<template>
  <div class="bg-teal">
    <section id="faqs-section" class="wrapper">
      <transition name="fade-2">
        <img
          src="@/assets/backgrounds/ascent-controls-faq-background-answer.png"
          alt="Ascent Controls FAQ Background 1"
          loading="lazy"
          v-if="currentAnswer"
        />
        <img
          src="@/assets/backgrounds/ascent-controls-faq-background.png"
          alt="Ascent Controls FAQ Background 2"
          loading="lazy"
          v-else
        />
      </transition>
      <article id="answer-container">
        <transition name="fade-1" mode="out-in">
          <p id="answer" v-show="currentAnswer" :key="currentAnswer">
            {{ currentAnswer }}
          </p>
        </transition>
      </article>
      <article id="questions-container">
        <h2>FAQ</h2>
        <h3>
          Our most commonly asked <span class="white-emphasis">questions</span>
        </h3>
        <ul id="questions-container">
          <li
            class="questions"
            v-for="faq in faqs"
            :key="faq.id"
            :class="{ active: isActive(faq.id) }"
            @click="setFaqCurrentAnswerId(faq.id)"
          >
            {{ faq.question }}
          </li>
        </ul>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          id: 1,
          question:
            "I like my excel spreadsheets. I have a routine down. Why should I consider switching?",
          answer:
            "Spreadsheets may be working now, but as your company and ICFR project grows, the number of controls, documents, touchpoints and users will also grow. Ascent will allow you to be more efficient in your job duties, and focus on tasks that add better value, allowing you to protect your organization from SOX and C-SOX related risks.",
        },
        {
          id: 2,
          question:
            "What do you mean by Ascent Controls has less ‘bells and whistles’ than other comparable enterprise-level software? How does this benefit / hinder me as my company grows?",
          answer:
            "Ascent is geared towards mid-market companies. Fully integrated GRC platforms offer features that are not practical for organizations with small or non-existent internal audit groups. Ascent offers a user-friendly, ICFR-focused platform with the most important features for control evaluators. It offers features that can scale up or down based on your company’s situation.",
        },
        {
          id: 3,
          question:
            "I’m convinced but I need to get my boss and IT to agree. Any ideas on how to pitch it to them?",
          answer:
            "Ascent offers a quick to implement, intuitive and low-cost solution that will immediately save evaluators, control owners and executives their most precious resource: time. Our software was built by control evaluators, so we understand the importance of IT security. This is why Ascent is built to minimize the amount of sensitive data saved on our North American servers and retain it within your company’s IT environment.",
        },
        {
          id: 4,
          question:
            "I’m ready to get started, what do I need to gather in preparation for set-up and onboarding to your software system?",
          answer:
            "Leave the details to Ascent. Provide us with your existing controls documentation and list of users and we will have you set up and using Ascent within two weeks! Onboarding and training are included in our low subscription cost.",
        },
        {
          id: 5,
          question:
            "I’m new in my role and have to design the whole internal controls process from scratch. Can you help me through it the first time, or will I have to hire a 3rd party consultant?",
          answer:
            "Starting a fresh project can be daunting. Ascent subscribers can leverage out free 'how to' training. We are happy to customize our training for an overview on how to document controls. If this still seems overwhelming, reach out to our sister firm to see if they can help you scope, document and test your controls with a project built to suit your company’s risk appetite and budget.",
        },
        {
          id: 6,
          question:
            "You mention that the software platform doesn’t host my documents? How does this benefit me? Will my IT Department be on board with this tool?",
          answer:
            "Our software was built by control evaluators, so we understand the importance of IT security. This is why Ascent is built to minimize the amount of sensitive data saved within the application, allowing it to be retained within your company’s IT environment. Ascent links to files using your company’s cloud-based file structure, such as MS Teams, SharePoint or G-suite. And the data that is on our servers remain in North America with SOC-compliant hosts.",
        },
        {
          id: 7,
          question:
            "My firm is exploring an IPO or in the process of an IPO, how will your software help me?",
          answer:
            "Public companies need to comply with regulations (e.g. SOX, C-SOX) where the CEO and CFO certify on the design and effectiveness of internal controls over financial reporting. Ascent has all the features to support these evaluations, including walkthroughs of control, assessing design and risk, testing effectiveness and sub-certification of controls.",
        },
        {
          id: 8,
          question:
            "My Executive Team doesn’t see the value in investing in a new tool, how do I convince them I need this to do my job?",
          answer:
            "Show them the cost benefit in efficiency gains. Our clients consistently attest to the time saved using Ascent to help with the evaluations. Or show them that the Ascent system is set up to perform even if critical employees exit the company. Easily filter for control owner controls and re-assign ownership and accountability in seconds.",
        },
        {
          id: 9,
          question:
            "Due to economic conditions and budget cuts, I can no longer afford my Internal Controls Department or Consultancy, can Ascent Controls replace them?",
          answer:
            "Although the Ascent platform cannot completely replace your internal controls function, or consultancy, it has been proven to significantly reduce the cost of your compliance projects. Our clients have reported a cost reduction of up to 70% compared to their manual processes before using Ascent. The platform is meant to make your Internal Control process more efficient and streamlined so whether you have an in-house employee or external consultant tasked with overseeing the project, the time spent on SOX and C-SOX compliance will be significantly reduced using Ascent.",
        },
        {
          id: 10,
          question:
            "Why does Ascent Controls cost so much less than other ICFR software solutions?",
          answer:
            "Ascent is geared towards mid-market companies. Fully integrated GRC platforms offer features that are not practical for organizations with small or non-existent internal audit groups. Ascent offers a user-friendly, ICFR-focused platform with the most important features for control evaluators. As a tool built by control evaluation experts, we also know the barriers that accompany software implementations, especially around internal controls compliance, which unfortunately is not always seen as a value driver in organizations. We have set the pricing low with key features to make it an easy decision; one you will not regret.",
        },
      ],
      currentFaqsId: null,
    };
  },
  methods: {
    setFaqCurrentAnswerId(id) {
      if (this.currentFaqsId != id) {
        this.currentFaqsId = id;
      } else {
        this.currentFaqsId = null;
      }
    },
    isActive(id) {
      return id === this.currentFaqsId;
    },
  },
  computed: {
    currentQuestion() {
      if (this.currentFaqsId !== null) {
        const currentQuestionInArray = this.faqs.filter((faq) => {
          return faq.id === this.currentFaqsId;
        });
        if (currentQuestionInArray.length === 1) {
          return currentQuestionInArray[0];
        }
      }
      return null;
    },
    currentAnswer() {
      if (this.currentQuestion !== null) {
        return this.currentQuestion.answer;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-teal {
  background: #52c9c3;

  @include for-desktop-down {
    display: none;
  }
}

#faqs-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  font-family: $primary-font;
  color: white;
  align-items: center;
  height: 1060px;

  img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    max-width: 1600px;
    background-size: cover;
    align-self: top;
    z-index: 2;
  }

  #answer-container {
    display: grid;
    grid-column: 1 / 2;
    grid-row: 1 / -1;
    align-items: center;
    justify-self: center;
    position: relative;
    z-index: 5;

    #answer {
      max-width: 40rem;
      font-size: 24px;
    }
  }

  #questions-container {
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    width: 80%;
    justify-self: right;
    align-self: center;
    position: relative;
    z-index: 5;
  }

  h2,
  h3 {
    color: white;
  }

  ul {
    max-height: 310px;
    overflow-y: scroll;
    padding-right: 2em;
    margin-top: 2em;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      width: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 20px;
      width: 3px;
      height: 10px;
    }
  }

  li.questions {
    color: white;
    margin-bottom: 1rem;
    padding-left: 2rem;
    cursor: pointer;
    font-size: 18px;
    transition: transform 0.25s ease-out, text-decoration 0.25s ease-out;
    opacity: 0.95;
    font-size: 18px;
    text-decoration: underline;
    -webkit-text-decoration-color: transparent;
    text-decoration-color: transparent;
    position: relative;

    &.active::before {
      font-family: "Font Awesome 5 Free";
      content: "\f060";
      font-weight: 900;

      position: absolute;
      left: 5px;
    }

    &.active,
    &:hover {
      transform: scale(0.95);
      transform-origin: top left;
      font-weight: bold;
      opacity: 1;
      -webkit-text-decoration-color: white;
      text-decoration-color: white;
    }
  }

  p {
    color: white;
  }

  .fade-2-enter-active,
  .fade-2-leave-active {
    transition: opacity 1s ease;
  }

  .fade-2-enter-from,
  .fade-2-leave-to {
    opacity: 0;
  }

  .fade-1-enter-active,
  .fade-1-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-1-enter-from,
  .fade-1-leave-to {
    opacity: 0;
  }
}
</style>
