<template>
  <section id="testimonial-section">
    <img
      src="@/assets/backgrounds/ascent-controls-testimonial-map.png"
      alt="Ascent Controls Testimonials Background"
      class="background-img"
      loading="lazy"
    />
    <section class="testimonial-content wrapper">
      <article class="quote-article" v-for="i in [currentIndex]" :key="i">
        <h2>Testimonials</h2>
        <blockquote class="quote-container">
          <span class="quote" v-html="currentTestimonial.quote"></span>
          <cite>
            <span class="quotee">
              {{ currentTestimonial.name }}
            </span>
            /
            <a
              :href="currentTestimonial.companyLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ currentTestimonial.company }}
            </a>
          </cite>
        </blockquote>
        <div class="quote-controls">
          <i @click="prev" class="prev-quote fas fa-chevron-left fa-2x"></i>
          <i @click="next" class="next-quote fas fa-chevron-right fa-2x"></i>
        </div>
      </article>
      <article class="quote-image">
        <img
          src="@/assets/testimonials/ascent-controls-testimonials.png"
          alt="Ascent Controls Testimonial Profile Images"
          loading="lazy"
        />
      </article>
    </section>
  </section>
</template>

<script>
export default {
  data() {
    return {
      testimonials: [
        {
          id: 1,
          name: "Erica B",
          title: "Manager, Financial Reporting",
          company: "Enerplus",
          companyLink: "https://www.enerplus.com/",
          binary: "1",
          quote:
            "With <span class='ascent-span'>Ascent Controls</span>, we were able to significantly reduce internal time spent on evaluations while concurrently reducing the external ICFR budget. This platform revolutionized the way we evaluate our internal controls, including empowering process owners to review and update their control documentation on a continual basis.",
        },
        {
          id: 2,
          name: "Shaun T",
          company: "Athabasca Oil Corp",
          companyLink: "https://www.atha.com/",
          binary: "0",
          quote:
            "Since we started using <span class='ascent-span'>Ascent Controls</span>, our company’s control owners can more effectively certify quarterly controls. The centralized platform gives all necessary stakeholders from our finance team to external auditors access to the most current list of controls and any supporting documentation. This tool has saved us significant time in training, data losses, and administration of our internal controls program.",
        },
        {
          id: 3,
          name: "Kelly N",
          title: "Controller,",
          company: "Ember Resources Inc.",
          companyLink: "https://emberresources.com/",
          binary: "1",
          quote:
            "The <span class='ascent-span'>Ascent Controls</span> dashboard allows me to quickly see the status of our ICFR project, what walkthroughs are outstanding, what controls need to be tested and what deficiencies are outstanding. This level of transparency did not exist prior to implementing <span class='ascent-span'>Ascent Controls</span>. Our team also uses <span class='ascent-span'>Ascent</span> as a checklist; people know what is expected of them each quarter through the certification module. I feel very comfortable using <span class='ascent-span'>Ascent Controls</span> as all documentation remains within our company’s IT environment through the cloud integration features.",
        },
      ],
      timer: null,
      currentIndex: 0,
    };
  },
  mounted: function () {
    this.startSlide();
  },

  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, 20000);
    },

    next: function () {
      clearInterval(this.timer);
      this.currentIndex += 1;
      this.timer = setInterval(this.next, 20000);
    },
    prev: function () {
      clearInterval(this.timer);
      this.currentIndex -= 1;
      this.timer = setInterval(this.next, 20000);
    },
  },

  computed: {
    currentTestimonial: function () {
      return this.testimonials[
        Math.abs(this.currentIndex) % this.testimonials.length
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
#testimonial-section {
  height: 1060px;
  background: #f5f6f6;
  display: grid;
  font-family: $primary-font;

  img.background-img {
    justify-self: center;
    align-self: center;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    overflow: hidden;
  }

  .testimonial-content {
    justify-self: center;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr max-content 1fr;
    column-gap: 2rem;
    height: 100%;

    @include biggest-down {
      grid-template-columns: 1fr;
    }
  }

  .quote-article {
    grid-row: 2/3;
    display: grid;
    grid-template-rows: min-content 1fr 100px;
    row-gap: 2rem;
    justify-self: right;
    align-self: end;
    max-width: 800px;

    @include biggest-down {
      grid-column: 1/-1;
      justify-self: center;
      z-index: 12;
    }
  }

  .quote-image {
    grid-column: 2;
    grid-row: 1/-1;
    align-self: center;
    justify-self: center;

    @include biggest-down {
      grid-column: 1/-1;
      grid-row: 1/-1;
      opacity: 0.25;
      z-index: 0;
    }
  }

  cite {
    margin-top: 1rem;
    display: block;
  }

  span.quote {
    max-width: 100px;
  }

  .quote {
    position: relative;
    quotes: "“" "”" "‘" "’";
    font-size: 2rem;
    line-height: 2.8rem;
    color: $primary-grey;
    align-self: end;
    z-index: 12;

    &:before {
      position: absolute;
      content: open-quote;
      font-size: 5rem;
      color: $primary-teal;
      font-family: Smokum, serif;
      top: 10px;
      left: -2.75rem;
    }

    &:after {
      position: absolute;
      content: close-quote;
      font-size: 5rem;
      color: $primary-teal;
      bottom: -12px;
      right: -4rem;
      font-family: Smokum, serif;
    }
  }

  ::v-deep .quote > .ascent-span {
    color: $primary-teal;
    font-weight: 800;
    text-transform: uppercase;
  }

  .quotee {
    color: $primary-teal;
    font-weight: bold;
  }

  .quote-controls {
    align-self: top;
  }

  .prev-quote,
  .next-quote {
    color: $light-grey;
    cursor: pointer;
    align-self: bottom;
    transition: color 0.5s ease;

    &:hover {
      color: $primary-teal;
    }
  }

  .prev-quote {
    margin-right: 3rem;
  }
}
</style>
