<template>
  <section id="about-section" class="wrapper">
    <article class="about-card">
      <h2>About</h2>
      <h3>
        <span class="teal-emphasis">Another spreadsheet</span>? It has to get
        done, why not <span class="teal-emphasis">make it fun</span>
      </h3>
      <p>
        <span class="teal-emphasis">ICFR</span> and
        <span class="teal-emphasis">GITC</span> evaluators in mid-market
        organizations have long been faced with few options for data management;
        rely on spreadsheets or pay hefty fees for solutions built for much
        larger organizations. Geared towards small and medium-sized
        corporations, Ascent Controls is on a mission to make the internal
        controls compliance process more efficient. Our secure web-based
        software helps evaluators, executives and stakeholders take the
        frustration out of their jobs providing them with a centralized and
        intuitive tool to quarterly certify on internal controls. You might even
        call it fun!
      </p>
      <p>
        With <span class="teal-emphasis">Ascent Controls</span>, you can
        walkthrough processes, identify and assess controls, test and track
        control efficacy, and efficiently report results and status to all
        stakeholders - all in one platform. Provide confidence and
        accountability in your internal control certification process with
        better data visibility.
      </p>
    </article>
    <article class="about-image">
      <img
        src="@/assets/about/about-ascent-controls.png"
        alt="About Ascent Controls"
        loading="eager"
      />
    </article>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#about-section {
  font-family: $primary-font;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 900px;
  padding: 5rem;

  @include for-big-desktop-down {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  h2 {
    margin-bottom: 1rem;
    width: min-content;
  }

  h3 {
    margin-bottom: 1rem;
    -webkit-text-decoration-color: $primary-teal; /* Safari */
    text-decoration-color: $primary-teal;
  }

  p {
    margin-bottom: 1rem;
  }

  .about-card {
    grid-column: 2/3;
    max-width: 80ch;

    @include for-big-desktop-down {
      grid-column: 1/-1;
      justify-self: center;
    }
  }

  .about-image {
    position: relative;
    grid-column: 1/2;

    @include for-big-desktop-down {
      grid-column: 1/-1;
    }

    img {
      position: absolute;
      z-index: -1;
      left: 50%;
      margin-left: -1750px;
      top: 50%;
      margin-top: -1000px;

      @include for-big-desktop-down {
        opacity: 0.25;
        margin-top: -980px;
        margin-left: -1575px;
      }

      @include for-phone-only {
        margin-top: -733px;
      }
    }
  }

  .teal-emphasis {
    color: $primary-teal;
    font-weight: 800;
  }
}
</style>
