<template>
  <header id="header" :style="headerBackground">
    <nav>
      <ul>
        <li>
          <a href="#top">
            <img
              src="@/assets/logo.png"
              alt="Ascent Controls Logo - Return to Home Link"
            />
          </a>
        </li>
        <li v-if="notMobile"><a href="#about-section">About</a></li>
        <li v-if="notMobile"><a href="#why-section">Why Ascent</a></li>
        <li v-if="notMobile"><a href="#how-section">How it works</a></li>
        <li v-if="notMobile"><a href="#features-section">Features</a></li>
        <li v-if="notMobile"><a href="#pricing-section">Pricing</a></li>
        <li v-if="notMobile"><a href="#faqs-section">FAQs</a></li>
        <li v-if="notMobile"><a href="#contact-section">Contact</a></li>
        <li v-if="notMobile">
          <blue-pill href="#contact-section">Request a Demo</blue-pill>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import BluePill from "@/components/buttons/BluePill";

export default {
  components: {
    BluePill,
  },
  data() {
    return {
      windowTop: 0,
      opacity: 0,
      headerBackground: {
        background:
          "linear-gradient(rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%)",
      },
    };
  },
  props: ["notMobile"],
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.onScroll;
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll(e) {
      this.windowTop = e.target.documentElement.scrollTop;
      this.opacity = this.windowTop / 500;
      if (this.opacity < 0.8) {
        this.headerBackground.background = `linear-gradient(rgba(255, 255, 255, ${this.opacity}) 60%, rgba(255, 255, 255, 0) 100%)`;
      } else {
        this.headerBackground.background = `linear-gradient(rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0) 100%)`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  padding: 0.75rem 2rem 3rem 2rem;
  position: fixed;
  width: 100%;
  z-index: 100;

  @include for-phone-only {
    position: relative;
  }
}

nav {
  max-width: 1600px;
  margin: 0 auto;
  font-family: Montserrat;
  font-weight: bold;
}

ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;

  @include for-big-desktop-down {
    li:nth-child(4) {
      display: none;
    }
  }

  @include for-desktop-down {
    li:nth-child(n + 2):nth-child(-n + 8) {
      display: none;
    }
  }
}

a {
  font-size: 0.875rem;
  color: $primary-grey;
  text-transform: uppercase;
  letter-spacing: 2px;
}
</style>
