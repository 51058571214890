<template>
  <section id="why-section" class="wrapper">
    <article>
      <h2>Why Ascent</h2>
      <h3>
        <span class="teal-emphasis">Internal Control</span> compliance made
        easier with <span class="teal-emphasis">Ascent</span>
      </h3>
      <ul>
        <li v-for="why in whys" :key="why.heading">
          <h4>{{ why.heading }}</h4>
          <p>{{ why.paragraph }}</p>
        </li>
      </ul>
    </article>
    <article>
      <img
        src="@/assets/why/ascent-controls-why.png"
        alt="Why Ascent Controls"
        loading="lazy"
      />
    </article>
  </section>
</template>

<script>
export default {
  data() {
    return {
      whys: [
        {
          heading: "Rise above administration.",
          paragraph:
            "Free yourself from cumbersome spreadsheets and endless email administration used to document and test your internal controls. Our centralized platform keeps everything organized so stakeholders can access all controls from one place.",
        },
        {
          heading: "Low risk. High ROI.",
          paragraph:
            "Our user-friendly platform returns a faster ROI than comparable software - both in cost and implementation. With the industry’s lowest flat licensing fee and a 2-week implementation what’s holding you back?",
        },
        {
          heading: "Features that matter.",
          paragraph:
            "We’ve cut the bloat and learning curve out of other enterprise software, focusing on practical functionality and features that matter most to stakeholders.",
        },
        {
          heading: "Achieve efficiency.",
          paragraph:
            "Spend more time managing actual risks and less time worrying about tracking requests or chasing documentation.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#why-section {
  font-family: $primary-font;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 1060px;

  @include for-big-desktop-down {
    grid-template-columns: 1fr;
    overflow: hidden;
  }

  @include for-tablet-landscape-down {
    min-height: unset;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    margin-bottom: 2rem;
    -webkit-text-decoration-color: $primary-teal; /* Safari */
    text-decoration-color: $primary-teal;
    max-width: 25ch;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 800;
    margin-bottom: 1rem;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .teal-emphasis {
    color: $primary-teal;
    font-weight: 800;
  }

  article {
    position: relative;
    display: grid;
    justify-self: center;
    justify-content: center;
    align-content: center;

    @include for-big-desktop-down {
      grid-column: 1/-1;
      grid-row: 1/-1;
    }

    img {
      position: absolute;
      left: 50%;
      margin-left: -500px;
      top: 50%;
      margin-top: -533px;
      z-index: 5;

      @include for-big-desktop-down {
        opacity: 0.15;
        margin-top: -408px;
        margin-left: -600px;
      }
    }
  }
}
</style>
