<template>
  <div class="bg-grey">
    <img
      src="@/assets/backgrounds/ascent-controls-background-04a.png"
      alt="Ascent Controls Background"
      class="background"
      loading="lazy"
    />
    <section id="how-section" class="wrapper">
      <h2>How it Works.</h2>
      <h3><span class="teal-emphasis">Ascent</span> in Action</h3>
      <nav>
        <ul id="hows-container">
          <li class="hows" v-for="how in hows" :key="how.id">
            <clear-pill
              @click="setCurrentHowId(how.id)"
              :class="{ active: isActive(how.id) }"
            >
              {{ how.nav }}
            </clear-pill>
          </li>
        </ul>
      </nav>
      <section class="how-result">
        <transition name="fade-1" mode="out-in">
          <article class="how-images" :key="currentHowsId">
            <img
              :src="require(`@/assets/how/${currentHow.img}`)"
              :alt="currentHow.imgAlt"
              loading="eager"
            />
          </article>
        </transition>
        <transition name="fade-2" mode="out-in">
          <article class="how-text" :key="currentHowsId">
            <h4>{{ currentHow.heading }}</h4>
            <article
              class="how-paragraph"
              v-html="currentHow.paragraph"
            ></article>
          </article>
        </transition>
      </section>
    </section>
  </div>
</template>

<script>
import ClearPill from "@/components/buttons/ClearPill";

export default {
  name: "ContactSection",
  components: {
    ClearPill,
  },
  data() {
    return {
      hows: [
        {
          id: 1,
          isActive: false,
          nav: "Identify Controls & Assess Risk",
          img: "how-01-ascent-controls-identify-controls-assess-risk.png",
          imgAlt:
            "Identify Controls & Assess Risk with Ascent Controls Software",
          heading: "Identify Controls & Assess Risk",
          paragraph:
            "<p>With <span class='ascent-span'>Ascent Controls</span>, you can easily create, modify or delete controls and set controls as “key” to create test plans in one centralized database. This single source of internal controls data enables you to easily access ICFR project status and key metrics such as control counts, key controls, percent of testing completed and walkthroughs. Embed a risk-based approach to your control design by using our Risk-Control Matrix, which links controls to process -specific risks, allowing you to focus on the most important risks when assessing your control design.</p>",
        },
        {
          id: 2,
          isActive: false,
          nav: "Walkthrough Processes",
          img: "how-02-ascent-controls-walkthrough-processes.png",
          imgAlt: "Walkthrough Processes with Ascent Controls Software",
          heading: "Walkthrough Processes",
          paragraph:
            "<p>Whether you have mature control evaluation program in place or if need to start from the beginning, <span class='ascent-span'>Ascent Controls</span> makes it easy to build, design, and manage your processes and controls. Quickly create, modify or delete processes and sub-processes. Identify and analyze the risk ranking of all your sub-processes on a single page and forget about compiling multiple versions of spreadsheets and supporting data. We make it easy to create a new compliance project or copy an existing one from a previous year, with the testing status data automatically resetting. Simply upload flowcharts and link process narratives or other supplemental information. In one click, process control owners can review their controls and sub-processes, certify on their controls quarterly, and update any deficiencies. Use the Walkthroughs and Changes features to easily identify process and control changes.</p>",
        },
        {
          id: 3,
          isActive: false,
          nav: "Test & Track Controls",
          img: "how-03-ascent-controls-test-track.png",
          imgAlt: "Test & Track Controls with Ascent Controls Software",
          heading: "Test & Track Controls",
          paragraph:
            "<p><span class='ascent-span'>Ascent Controls</span> provides a comprehensive and organized interface to test your existing controls to verify they are operating effectively. Easily manage documentation requests with our intuitive and user-friendly platform. There isn't always a linear relationship between the individuals who provide populations, who retain control documentation, and who own controls, which is why <span class='ascent-span'>Ascent Controls</span> provides a Documentation Request Module. Our documentation request module simplifies the evaluator's time spent tracking multiple requests through email, providing them with one summary list.</p><p>Create test plans, assign tests to, link to test sheets and supporting documentation. Our test sheets are integrated with Microsoft 365 and G Suite for increased flexibility and functionality. No more guesswork on the status of your ICFR compliance program. Monitoring progress is as simple as looking at the dashboard or creating a testing summary report.</p>",
        },
        {
          id: 4,
          isActive: false,
          nav: "Report Results & Statuses",
          img: "how-04-ascent-controls-report-results-status.png",
          imgAlt:
            "Efficiently Report Results & Statuses with Ascent Controls Software",
          heading: "Efficiently Report Results & Statuses",
          paragraph:
            "<p><span class='ascent-span'>Ascent Controls</span> truly enables full control owner accountability, allowing them to read, review and certify on their controls and processes. We offer two customizable and automated control certifications for control owners and senior management and executives. Our platform also includes an annual user-driven walkthrough module, facilitating better visibility and accountability over ICFR processes and controls. Easily send reminders to owners to complete certifications and update the status of deficiencies. <span class='ascent-span'>Ascent Controls</span>' internal controls compliance platform integrates all of your data in a centralized database with drill-down capabilities and comprehensive hyperlinked reporting. You can check key statistics on controls and processes alongside testing and deficiency remediation progress in one user-friendly dashboard. Our issue reporting includes continuities so you can quickly assess changes year over year.</p>",
        },
      ],
      currentHowsId: 1,
    };
  },
  methods: {
    setCurrentHowId(id) {
      this.currentHowsId = id;
    },
    isActive(id) {
      return id === this.currentHowsId;
    },
  },
  computed: {
    currentHow() {
      let how = this.hows.find((how) => how.id === this.currentHowsId);
      return how;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-grey {
  background: $lightest-grey;
  display: grid;
  position: relative;

  @include for-big-desktop-down {
    display: none;
  }

  img.background {
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    margin-left: -2100px;
    margin-top: -180px;
    grid-row: 1/-1;
    grid-column: 1/-1;
  }
}

#how-section {
  z-index: 5;
  grid-row: 1/-1;
  grid-column: 1/-1;
  font-family: $primary-font;
  display: grid;
  grid-template-rows: max-content max-content max-content max-content;
  justify-self: center;
  align-self: center;
  margin: 5rem 0;

  h2,
  h3 {
    justify-self: center;
    text-align: center;
    -webkit-text-decoration-color: $primary-teal; /* Safari */
    text-decoration-color: $primary-teal;
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 3rem;
  }

  h4 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  nav {
    ul {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5rem;
    }
  }

  section.how-result {
    display: grid;
    grid-template-columns: 3fr 2fr;

    @include biggest-down {
      grid-template-columns: 1fr;
    }
  }

  article.how-text,
  article.how-images {
    @include biggest-down {
      grid-row: 1/-1;
      grid-columns: 1/-1;
      justify-self: center;
    }
  }

  ::v-deep .how-paragraph > p {
    margin-bottom: 1rem;
    > .ascent-span {
      color: $primary-teal;
      font-weight: 800;
      text-transform: uppercase;
    }
  }

  img {
    max-height: 540px;
  }

  span.teal-emphasis {
    color: $primary-teal;
    font-weight: 800;
  }

  .active {
    color: white;
    background: $primary-teal;
    box-shadow: -13px 23px 46px 0px rgba(43, 136, 131, 0.41);
  }

  .fade-1-enter-active,
  .fade-1-leave-active {
    transition: opacity 0.25s ease-in-out;
    width: inherit;
  }

  .fade-1-enter-from,
  .fade-1-leave-to {
    opacity: 0;
    width: inherit;
  }

  .fade-2-enter-active,
  .fade-2-leave-active {
    transition: opacity 0.2s ease-in-out;
    width: inherit;
  }

  .fade-2-enter-from,
  .fade-2-leave-to {
    opacity: 0;
    width: inherit;
  }
}
</style>
