<template>
  <section id="above-fold" class="wrapper">
    <article class="call-to-action">
      <h1>
        <span class="teal-emphasis">Controls</span> are easy. Tracking them can
        be too, with <span class="teal-emphasis">Ascent</span>
      </h1>
      <p>
        <span class="teal-emphasis">ASCENT Controls</span> is the most
        cost-effective internal controls management software on the market. Our
        application is user-friendly, intuitive, and focused on practical
        features that matter most for mid-market public companies.
      </p>
      <article class="button-list">
        <teal-pill href="#contact-section" class="demo-button">
          Request a Demo
        </teal-pill>
        <blue-pill href="#how-section" class="trial-button">
          How it Works
        </blue-pill>
      </article>
    </article>
    <article class="hero-image">
      <img
        src="@/assets/abovefold/ascent-controls.png"
        alt="Ascent Controls Main Image - iPad with Ascent software surrounded by user images"
        class="hero"
      />
    </article>
  </section>
</template>

<script>
import TealPill from "@/components/buttons/TealPill";
import BluePill from "@/components/buttons/BluePill";
export default {
  components: {
    TealPill,
    BluePill,
  },
};
</script>

<style lang="scss" scoped>
#above-fold {
  padding-top: 200px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  min-height: 1100px;

  @include for-big-desktop-down {
    grid-template-columns: 1fr;
  }

  @include for-tablet-landscape-down {
    grid-template-columns: 1fr;
  }

  @include for-phone-only {
    padding-top: 50px;
    min-height: unset;
  }

  .call-to-action {
    position: relative;
    z-index: 2;

    @include for-big-desktop-down {
      grid-column: 1/-1;
      max-width: 80ch;
      justify-self: center;
    }

    @include for-phone-only {
      max-width: 38ch;
      min-width: 10ch;
      padding-top: 25px;
    }

    h1 {
      font-size: 3.75rem;
      font-family: Montserrat;
      font-weight: 200;
      text-align: left;
      margin-bottom: 1.5rem;
      text-decoration: underline;
      -webkit-text-decoration-color: $primary-teal; /* Safari */
      text-decoration-color: $primary-teal;
      -webkit-text-decoration-thickness: 0.2rem;
      text-decoration-thickness: 0.2rem;
      -webkit-text-underline-offset: 5px;
      text-underline-offset: 5px;

      @include for-phone-only {
        font-size: 2.5rem;
        max-width: 100%;
      }
    }

    p {
      text-align: left;
      margin-bottom: 1.5rem;
    }

    span.teal-emphasis {
      color: $primary-teal;
      font-weight: 800;
    }

    .demo-button {
      margin-right: 1rem;
    }

    .button-list {
      display: flex;
    }

    ::v-deep .button-list > .trial-button {
      @include for-big-desktop-down {
        display: none;
      }
    }
  }

  .hero-image {
    position: relative;

    @include for-big-desktop-down {
      grid-column: 1/-1;
    }

    img {
      position: absolute;
      z-index: -1;
      left: 50%;
      margin-left: -645px;
      top: 50%;
      margin-top: -1480px;

      @include for-big-desktop-down {
        opacity: 0.25;
        margin-top: -1900px;
      }

      @include for-phone-only {
        margin-top: -1780px;
      }
    }
  }

  @include for-phone-only {
    .button-list {
      visibility: hidden;
    }
  }
}
</style>
