<template>
  <a :href="href"><slot /></a>
</template>

<script>
export default {
  props: ["href"],
};
</script>

<style lang="scss" scoped>
a {
  color: $primary-teal;
  font-size: 14px;
  border: 2px solid $primary-teal;
  font-family: Montserrat;
  font-weight: 800;
  padding: 1rem 2rem;
  border-radius: 100px;
  transition: background-color 0.25s ease-out;
  display: block;
  letter-spacing: 3px;
  text-align: center;
  width: max-content;
  text-transform: uppercase;

  &:hover {
    color: white;
    background: $primary-teal;
    box-shadow: -13px 23px 46px 0px rgba(43, 136, 131, 0.41);
  }
}
</style>
