<template>
  <section id="pricing-section" class="wrapper">
    <article class="our-plan">
      <h2>Pricing</h2>
      <h3>Our <span class="teal-emphasis">plans</span></h3>
      <p>
        <span class="teal-emphasis">ASCENT Controls</span> produces a faster ROI
        both in cost and implementation than comparable software products. It is
        currently the most cost effective internal controls management software
        on the market.
      </p>
      <teal-pill href="#contact-section">Get Started</teal-pill>
    </article>
    <article class="pricing-card-one float-card">
      <h4>Single Company</h4>
      <ul>
        <li>
          Unlimited User Seats
          <i class="fas fa-check"></i>
        </li>
        <li>
          Upload of Existing Company Data
          <i class="fas fa-check"></i>
        </li>
        <li>
          Initial Training & Implementation
          <i class="fas fa-check"></i>
        </li>
        <li>
          SharePoint Site Set-up for Linked Files
          <i class="fas fa-check"></i>
        </li>
        <li>
          Additional User Support
          <i class="fas fa-check"></i>
        </li>
      </ul>
      <teal-box href="#contact-section">BUY FOR $9500 / YEAR</teal-box>
    </article>
    <article class="pricing-card-two float-card">
      <h4>Multi-Company</h4>
      <ul>
        <li>
          Accounting Consulting Firms
          <i class="fas fa-check"></i>
        </li>
        <li>
          Multi-User Licensing
          <i class="fas fa-check"></i>
        </li>
        <li>
          Consulting-Based Implementation
          <i class="fas fa-check"></i>
        </li>
        <li>
          Team Onboarding & Training
          <i class="fas fa-check"></i>
        </li>
        <li>
          Additional User Support
          <i class="fas fa-check"></i>
        </li>
      </ul>
      <blue-box href="#contact-section">CONTACT US TO LEARN MORE</blue-box>
    </article>
  </section>
</template>

<script>
import TealPill from "@/components/buttons/TealPill";
import TealBox from "@/components/buttons/TealBox";
import BlueBox from "@/components/buttons/BlueBox";

export default {
  components: {
    TealPill,
    TealBox,
    BlueBox,
  },
};
</script>

<style lang="scss" scoped>
#pricing-section {
  font-family: $primary-font;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 1060px;
  align-items: center;
  gap: 4rem;

  @include for-big-desktop-down {
    grid-template-columns: 1fr 1fr;
  }

  @include for-tablet-landscape-down {
    grid-template-columns: 1fr;
  }

  h4 {
    font-size: 2rem;
    padding: 2rem;
  }

  p {
    max-width: 40ch;
  }

  ul {
    padding: 0rem;
    width: 100%;

    li {
      &:nth-child(even) {
        background: $lightest-grey;
      }
      padding: 2rem;
      display: flex;
      justify-content: space-between;

      i {
        padding: 0;
        font-weight: bolder;
      }
    }
  }

  .our-plan {
    h2 {
      margin-bottom: 2rem;
    }
    h3 {
      margin-bottom: 2rem;
      -webkit-text-decoration-color: $primary-teal; /* Safari */
      text-decoration-color: $primary-teal;
    }
    p {
      margin-bottom: 2rem;
    }

    @include for-big-desktop-down {
      grid-column: 1/-1;
      justify-self: center;
    }
  }

  .float-card {
    z-index: 1;
    box-shadow: -13px 23px 46px 0px rgba(43, 136, 131, 0.41);
  }

  .pricing-card-one {
    h4 {
      color: white;
      background: $dark-teal;
      font-size: 2rem;
      padding: 2rem;
    }

    i {
      color: $dark-teal;
    }
  }

  .pricing-card-two {
    h4 {
      color: white;
      background: $dark-blue;
    }

    i {
      color: $dark-blue;
    }
  }

  span.teal-emphasis {
    color: $primary-teal;
    font-weight: 800;
  }
}
</style>
