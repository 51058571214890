<template>
  <footer>
    <div class="upper-footer wrapper">
      <nav>
        <ul>
          <li>
            <a href="#top">
              <img
                src="@/assets/logo.png"
                alt="Ascent Controls Logo"
                loading="lazy"
              />
            </a>
          </li>
          <li v-if="notMobile"><a href="#about-section">About</a></li>
          <li v-if="notMobile"><a href="#why-section">Why Ascent</a></li>
          <li v-if="notMobile"><a href="#how-section">How it works</a></li>
          <li v-if="notMobile"><a href="#features-section">Features</a></li>
          <li v-if="notMobile"><a href="#pricing-section">Pricing</a></li>
          <li v-if="notMobile"><a href="#faqs-section">FAQs</a></li>
          <li v-if="notMobile"><a href="#contact-section">Contact</a></li>
          <li v-if="notMobile">
            <teal-pill href="#contact-section">Request a Demo</teal-pill>
          </li>
        </ul>
      </nav>
    </div>
    <div class="lower-footer bg-teal">
      <div class="lower-footer-contact wrapper">
        <span
          >&copy; Copyright 2017{{ footerCopyright }}, Ascent Controls, All
          Rights Reserved</span
        >
        <span>Website Designed by Passerelle Marketing</span>
      </div>
    </div>
  </footer>
</template>

<script>
import TealPill from "@/components/buttons/TealPill";

export default {
  components: {
    TealPill,
  },
  props: ["notMobile"],
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    footerCopyright() {
      let copyright;
      if (new Date().getFullYear() > 2017) {
        copyright = "-" + new Date().getFullYear();
      } else {
        copyright = "";
      }
      return copyright;
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  color: white;
  padding-bottom: 0;
  font-size: 1rem;
  display: grid;
  font-family: Montserrat;

  .upper-footer {
    width: 100%;
  }

  div {
    display: grid;
  }

  .upper-footer {
    background: white;
  }

  .lower-footer {
    background: $primary-teal;
    padding: 1rem;
  }

  .lower-footer-contact {
    background: $primary-teal;
    width: 100%;
    padding: 1rem 0;
    display: flex;
  }

  span {
    &:first-child {
      margin-right: auto;
    }
  }

  nav {
    width: 100%;
    margin: 0 auto;
    font-weight: bold;
  }

  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;

    @include for-desktop-down {
      li:nth-child(n + 2) {
        display: none;
      }
    }
  }

  a {
    font-size: 0.875rem;
    color: $primary-grey;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  @include for-desktop-down {
    ul {
      justify-content: space-around;
    }
  }
}
</style>
