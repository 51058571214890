<template>
  <div class="bg-blue">
    <section id="features-section" class="wrapper">
      <h2>Features</h2>
      <h3>
        <span class="white-emphasis">Practical functionality</span> built by
        evaluators for evaluators.
      </h3>
      <article>
        <div class="icon-container">
          <img
            src="@/assets/features/icon1-ascent-controls-data-security-cloud-integration.png"
            alt=""
            loading="lazy"
          />
        </div>
        <h4>Data Security & Cloud Integration</h4>
        <p>
          Our cloud-based integration provides links to supporting documents,
          ensuring your sensitive company data stays within your company, while
          also reducing duplicate files.
        </p>
      </article>
      <article>
        <div class="icon-container">
          <img
            src="@/assets/features/icon2-ascent-controls-user-friendly-interface.png"
            alt=""
            loading="lazy"
          />
        </div>
        <h4>User-Friendly Interface</h4>
        <p>
          You won’t miss your spreadsheets for long! Process and control owners
          are able to review their controls and sub processes, certify
          quarterly, and update deficiencies, in one click.
        </p>
      </article>
      <article>
        <div class="icon-container">
          <img
            src="@/assets/features/icon3-ascent-controls-single-sign-on.png"
            alt=""
            loading="lazy"
          />
        </div>
        <h4>Single Sign-On</h4>
        <p>
          Can’t remember your password. No problem! With our single sign on you
          log-in once and whether your accessing from and email or other link
          you’ll be taken right into the platform.
        </p>
      </article>
      <article>
        <div class="icon-container">
          <img
            src="@/assets/features/icon4-ascent-controls-one-click-roll-forward.png"
            alt=""
            loading="lazy"
          />
        </div>
        <h4>One-Click Roll Forward</h4>
        <p>
          Easily create a new project from scratch or copy an existing
          compliance project from previous years. In one click your new project
          is reset and ready for the current year..
        </p>
      </article>
      <article>
        <div class="icon-container">
          <img
            src="@/assets/features/icon5-ascent-controls-automated-workflows-reminders.png"
            alt=""
            loading="lazy"
          />
        </div>
        <h4>Automated Workflows & Reminders</h4>
        <p>
          Spend less time emailing and tracking down population and sample
          requests. Evaluators can track and access requests in one place, while
          allowing control owners to understand their requirements.
        </p>
      </article>
      <article>
        <div class="icon-container">
          <img
            src="@/assets/features/icon6-ascent-controls-on-demand-ic-consulting-support.png"
            alt=""
            loading="lazy"
          />
        </div>
        <h4>On-Demand IC Consulting Support</h4>
        <p>
          Need to ask an expert? Or help designing your processes? Our partner
          firm, PEAK Advisory specializes in Internal Controls Consulting to
          help fill gaps in your internal team, or provide support as required.
        </p>
      </article>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.bg-blue {
  color: white;
  background: $dark-blue;
  display: grid;
  align-content: center;
  min-height: 1060px;
}

#features-section {
  font-family: $primary-font;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: min-content;
  column-gap: 5rem;
  row-gap: 1rem;
  align-items: top;

  @include biggest-down {
    grid-template-columns: 1fr 1fr;
  }

  @include for-tablet-landscape-down {
    grid-template-columns: 1fr;
    margin: 5rem 0;
  }

  h2,
  h3 {
    grid-column: 1 / -1;
    justify-self: center;
    text-align: center;
    color: white;
    max-width: 25ch;
    margin-bottom: 2rem;
  }

  article {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-auto-rows: min-content;
    gap: 1rem;

    @include for-tablet-landscape-down {
      justify-self: center;
    }

    h4 {
      color: white;
      font-size: 1.5rem;
      align-self: end;
      justify-self: left;
      max-width: 14ch;
    }

    p {
      color: white;
      grid-column: 1 / -1;
    }

    .icon-container {
      align-self: end;
      background: white;
      border-radius: 100%;
      display: grid;
      width: 100px;
      height: 100px;

      img {
        max-width: 50px;
        max-height: 50px;
        justify-self: center;
        align-self: center;
      }
    }
  }

  .white-emphasis {
    font-weight: 800;
  }
}
</style>
