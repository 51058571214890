<!-- Contact Section -->
<template>
  <div class="bg-blue">
    <section id="contact-section" class="wrapper">
      <article>
        <h2>Contact</h2>
        <h3>
          <span class="underline"
            ><span class="extra-bold">Get started</span> today.</span
          >
        </h3>
        <p>Have your system implemented and ready to use within 2-weeks!</p>
      </article>
      <article class="form-submitted" v-if="contactSubmitted">
        <h3>Thank You!</h3>
        <p>
          Your contact information has been sent successfully. A representative
          from Ascent should be contacting you in 1-2 business days about your
          inquiry.
        </p>
      </article>
      <form v-on:submit.prevent="submitForm" v-if="!contactSubmitted">
        <div>
          <input
            type="text"
            name="first-name"
            id="contact-first-name"
            v-model.trim="form.first_name"
            @focus="firstFocused"
            @blur="firstFocused"
          />
          <label for="contact-first-name"
            >First Name
            <span class="error" v-if="v$.form.first_name.$error"
              >is required.</span
            ></label
          >
        </div>
        <div>
          <input
            type="text"
            name="last-name"
            id="contact-last-name"
            v-model.trim="form.last_name"
            @focus="lastFocused"
            @blur="lastFocused"
          />
          <label for="contact-last-name"
            >Surname
            <span class="error" v-if="v$.form.last_name.$error"
              >is required.</span
            ></label
          >
        </div>
        <div>
          <input
            type="text"
            name="company"
            id="contact-company"
            v-model.trim="form.company"
            @focus="companyFocused"
            @blur="companyFocused"
          />
          <label for="contact-company"
            >Company
            <span class="error" v-if="v$.form.company.$error"
              >is required. (Please type N/A if you do not wish to
              disclose).</span
            ></label
          >
        </div>
        <div>
          <input
            type="text"
            name="email"
            id="contact-email"
            v-model.trim="form.email"
            @focus="emailFocused"
            @blur="emailFocused"
          />
          <label for="contact-email" type="email"
            >E-Mail
            <span class="error" v-if="v$.form.email.$error"
              >is required (& be an email).</span
            ></label
          >
        </div>
        <div>
          <input
            type="text"
            name="phone-number"
            id="contact-phone-number"
            maxlength="14"
            :value="form.phone_number"
            @focus="phoneFocused"
            @blur="phoneFocused"
            @input="acceptNumber"
          />
          <label for="contact-phone-number"
            >Phone Number
            <span class="error" v-if="v$.form.phone_number.$error"
              >is required.</span
            ></label
          >
        </div>
        <div>
          <select
            name="inquiry-type"
            id="contact-inquiry-type"
            v-model.trim="form.inquiry_type"
          >
            <option disabled value="">Inquiry Type</option>
            <option>General inquiry</option>
            <option>Schedule a demo</option>
            <option>I'm ready to purchase</option>
            <option>Multi-company licensing inquiry</option>
            <option>Request a call back</option>
            <option>Other</option>
          </select>
        </div>
        <white-pill type="submit">Submit</white-pill>
        <div v-if="v$.form.inquiry_type.$error">
          <span class="error inquiry-error">
            Please specify an Inquiry Type.
          </span>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, alpha, email } from "@vuelidate/validators";
import WhitePill from "@/components/buttons/WhitePill";

export default {
  name: "ContactSection",
  components: {
    WhitePill,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      errorMessage: "",
      focused: false,
      contactSubmitted: false,
      form: {
        first_name: "",
        last_name: "",
        company: "",
        email: "",
        phone_number: "",
        inquiry_type: "",
        city: "",
        country: "",
        ip_address: "",
      },
    };
  },
  validations() {
    return {
      form: {
        first_name: { required, alpha },
        last_name: { required, alpha },
        company: { required },
        email: { required, email },
        phone_number: { required },
        inquiry_type: { required },
      },
    };
  },
  mounted() {
    // fetch("https://extreme-ip-lookup.com/json/")
    //   .then((res) => res.json())
    //   .then((response) => {
    //     this.form.country = response.country;
    //     this.form.city = response.city;
    //     this.form.ip_address = response.query;
    //   })
    //   .catch((data, status) => {
    //     console.log("Request failed");
    //     console.log(data);
    //     console.log(status);
    //   });
  },
  methods: {
    firstFocused() {
      const input = document.getElementById("contact-first-name");
      const inputContainer = input.parentNode;
      const inputActive = input == document.activeElement;
      const inputEmpty = input.value.length == 0;
      if (inputActive || inputEmpty == false) {
        inputContainer.classList.add("focused");
      } else {
        inputContainer.classList.remove("focused");
      }
    },
    lastFocused() {
      const input = document.getElementById("contact-last-name");
      const inputContainer = input.parentNode;
      const inputActive = input == document.activeElement;
      const inputEmpty = input.value.length == 0;
      if (inputActive || inputEmpty == false) {
        inputContainer.classList.add("focused");
      } else {
        inputContainer.classList.remove("focused");
      }
    },
    companyFocused() {
      const input = document.getElementById("contact-company");
      const inputContainer = input.parentNode;
      const inputActive = input == document.activeElement;
      const inputEmpty = input.value.length == 0;
      if (inputActive || inputEmpty == false) {
        inputContainer.classList.add("focused");
      } else {
        inputContainer.classList.remove("focused");
      }
    },
    emailFocused() {
      const input = document.getElementById("contact-email");
      const inputContainer = input.parentNode;
      const inputActive = input == document.activeElement;
      const inputEmpty = input.value.length == 0;
      if (inputActive || inputEmpty == false) {
        inputContainer.classList.add("focused");
      } else {
        inputContainer.classList.remove("focused");
      }
    },
    phoneFocused() {
      const input = document.getElementById("contact-phone-number");
      const inputContainer = input.parentNode;
      const inputActive = input == document.activeElement;
      const inputEmpty = input.value.length == 0;
      if (inputActive || inputEmpty == false) {
        inputContainer.classList.add("focused");
      } else {
        inputContainer.classList.remove("focused");
      }
    },
    acceptNumber($event) {
      let x = $event.target.value;
      var replacedInput = x
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.value = !replacedInput[2]
        ? replacedInput[1]
        : "(" +
          replacedInput[1] +
          ") " +
          replacedInput[2] +
          (replacedInput[3] ? "-" + replacedInput[3] : "");
      this.form.phone_number = this.value;
    },
    submitForm() {
      this.v$.$touch();
      if (this.v$.$error) return;
      axios
        .post(
          "https://5prtcsvm8j.execute-api.us-west-2.amazonaws.com/Prod/send",
          this.form
        )
        .then((res) => {
          //Perform Success Action
          console.log(res);
          this.contactSubmitted = true;
        })
        .catch((error) => {
          // error.response.status Check status code
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#contact-section {
  position: relative;
  z-index: 100;
  color: white;
  padding: 8rem 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: $primary-font;
  grid-row-gap: 2rem;

  @include for-desktop-down {
    grid-template-columns: 1fr;
  }
}

article {
  // min-height: 320px;

  h2 {
    color: white !important;
    margin-bottom: 1rem;
  }

  h3 {
    color: white !important;
    margin-bottom: 3rem;
    span.extra-bold {
      font-weight: 800;
    }
  }

  p {
    color: white !important;
    max-width: 26ch !important;
    font-size: 30px;
    font-weight: bold;
  }
}

form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;

  input,
  select {
    border: none;
    padding: 1rem;
    padding-left: 1.5rem;
    min-width: 100%;
    // height: 18px;
    border-radius: 100px;
    background: #82ceee;
    color: white;
  }

  > div {
    position: relative;
    border-radius: 100px;
    height: 50px;

    &:focus-within,
    &:hover {
      box-shadow: 0px 5px 15px 0px $dark-blue;
    }

    input {
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
      outline: 0;
    }

    label {
      color: white;
      position: absolute;
      top: 1rem;
      bottom: 0;
      left: 1.5rem;
      width: 100%;
      z-index: 2;
      transition: transform 400ms;
      transition-timing-function: ease-in-out;
      pointer-events: none;
    }

    select {
      padding-left: 1rem;
      outline: none;
    }

    /** active label */
    &.focused {
      label {
        //move the x coordinate and reduce size
        transform-origin: top left;
        transform: translate(0.5rem, -0.9rem) scale(0.74);
      }
    }
  }
  div:nth-child(1) {
    grid-column: span 2;
  }
  div:nth-child(2) {
    grid-column: span 2;
  }
  div:nth-child(3) {
    grid-column: span 4;
  }
  div:nth-child(4) {
    grid-column: span 2;
  }
  div:nth-child(5) {
    grid-column: span 2;
  }
  div:nth-child(6) {
    grid-column: span 3;
  }
  div:nth-child(8) {
    grid-column: span 3;
    padding-left: 1.1rem;
  }

  div:last-child {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }

  .error {
    color: #fed8b1;
    font-weight: 700;
    text-shadow: 1px 1px orange;
  }

  @include for-phone-only {
    grid-template-columns: 1fr;
    div {
      grid-column: span 1 !important;
    }
    button {
      grid-row-end: 9;
    }
  }
}
</style>
