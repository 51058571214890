<template>
  <button type="submit"><slot /></button>
</template>

<script>
export default {
  props: ["href"],
};
</script>

<style lang="scss" scoped>
button {
  color: $dark-blue !important;
  font-size: 14px;
  background: white;
  font-weight: 800;
  padding: 1rem;
  border: none;
  border-radius: 100px;
  box-shadow: -13px 23px 46px 0px rgba(20, 112, 160, 0.44);
  transition: background-color 0.25s ease-out;
  width: 100%;
  display: block;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;

  &:hover,
  &:focus {
    outline: none;
    background: $lightest-blue;
  }
}
</style>
