<template>
  <ascent-header class="nav" :notMobile="notMobile"></ascent-header>
  <!-- Mains -->
  <main ref="toast">
    <!-- Hero / Above the Fold -->
    <above-fold id="above-fold"></above-fold>

    <!-- About Section -->
    <about-section v-if="notMobile"></about-section>

    <!-- Why Ascent Section -->
    <why-section v-if="notMobile"></why-section>

    <!-- How It Works Section -->
    <how-section v-if="notMobile"></how-section>

    <!-- Features Section -->
    <features-section v-if="notMobile"></features-section>

    <!-- Pricing Section -->
    <pricing-section v-if="notMobile"></pricing-section>

    <!-- FAQs -->
    <faqs-section v-if="notMobile"></faqs-section>

    <!-- Testimonials Section -->
    <testimonials-section v-if="notMobile"></testimonials-section>

    <contact-section></contact-section>
  </main>

  <!-- Footer Section -->

  <ascent-footer :notMobile="notMobile"></ascent-footer>
</template>

<script>
import AscentHeader from "@/components/AscentHeader";
import AboveFold from "@/components/AboveFold";
import AboutSection from "@/components/AboutSection";
import WhySection from "@/components/WhySection";
import HowSection from "@/components/HowSection";
import FeaturesSection from "@/components/FeaturesSection";
import PricingSection from "@/components/PricingSection";
import FaqsSection from "@/components/FaqsSection";
import TestimonialsSection from "@/components/TestimonialsSection";
import ContactSection from "@/components/ContactSection";
import AscentFooter from "@/components/AscentFooter";

export default {
  components: {
    AscentHeader,
    AboveFold,
    AboutSection,
    WhySection,
    HowSection,
    FeaturesSection,
    PricingSection,
    FaqsSection,
    TestimonialsSection,
    ContactSection,
    AscentFooter,
  },
  data() {
    return {
      mobileBreakPoint: 600,
      width: 0,
      notMobile: false,
    };
  },
  props: ["mobile"],
  methods: {
    onResize() {
      this.width = document.body.clientWidth;
      if (this.width < this.mobileBreakPoint) {
        this.notMobile = false;
      } else {
        this.notMobile = true;
      }
    },
  },
  mounted() {
    this.width = document.body.clientWidth;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.onResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
@import "@/styles/reset";
@import "@/styles/fonts";
@import "@/styles/utilities";

#app {
  overflow: hidden;

  a,
  button {
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  li {
    z-index: 2;
  }

  h2 {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 800;
    letter-spacing: 5px;
    color: $primary-teal;
  }

  h3 {
    color: $dark-grey;
    font-size: 4rem;
    font-weight: 200;
    text-decoration: underline;
    -webkit-text-decoration-thickness: 0.2rem;
    text-decoration-thickness: 0.2rem;
    -webkit-text-underline-offset: 5px;
    text-underline-offset: 5px;
  }

  h4 {
    color: $primary-grey;
  }

  p,
  li {
    color: $primary-grey;
    font-family: $primary-font;
    line-height: 175%;
    max-width: 60ch;
  }

  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
